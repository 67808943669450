import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Maintain Posting" } }, [_c("select-input", { key: "CountryId", attrs: { "label": "Country", "reference": "web-analytics.common.ppv-countries", "source": "countryId", "source-label": "countryCode", "rules": "required", "value": _vm.countryId, "span": 4, "disabled": true, "on-select-change": _vm.onCountryChange } }), _c("text-input", { key: "Company", attrs: { "label": "Company", "span": 4, "value": _vm.company, "rules": "required" } }), _c("text-input", { key: "Journal", attrs: { "label": "Journal", "span": 8, "rules": "required", "placeholder": "Type Journal" } }), _c("text-input", { key: "JournalLine", attrs: { "label": "Journal Line", "span": 8, "rules": "required|number", "placeholder": "Type Journal Line" } }), _c("text-input", { key: "Item", attrs: { "label": "Item", "span": 12, "placeholder": "Type Item" } }), _c("select-input", { key: "Segment5", attrs: { "label": "Segment5", "reference": "web-analytics.common.ppv-segment5", "source": "segment5", "source-label": "segment5Desc", "placeholder": "Select Segment5", "rules": "required", "span": 12 } }), _c("text-input", { key: "Adjustment", attrs: { "label": "Adjustment", "span": 12, "rules": _vm.regex10digits5frac, "placeholder": "Type Adjustment" } }), _c("text-input", { key: "OtherRefFields", attrs: { "label": "Other Ref Fields", "span": 12, "rules": "required", "placeholder": "Type Other Ref Fields" } }), _c("select-input", { key: "Facility", attrs: { "label": "Facility", "default-active-first-option": "", "data-source": _vm.facilities, "source": "facilityId", "source-label": "facilityDesc", "value": _vm.defaultFacility, "rules": "required", "span": 24, "placeholder": "Select Facility" } })], 1);
};
var staticRenderFns$1 = [];
var CreateMaintainPostings_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CreateMaintainPostings",
  inject: ["crud"],
  mixins: [MyUtil],
  data() {
    return {
      facilities: [],
      company: "",
      srcglkey: "",
      apiUrl: apiUrl$1,
      countryId: this.crud.getQueryString("CountryID").value
    };
  },
  computed: {
    defaultFacility() {
      if (this.countryId == 1) {
        return "DC";
      }
      return "NZ";
    }
  },
  created() {
    this.getFacilities();
    this.getCompany();
    this.storeValue("CountryId", this.countryId);
    this.storeValue("Facility", this.defaultFacility);
  },
  methods: {
    getFacilities() {
      this.axios.get(`${this.apiUrl}/web-analytics/common/ppv-facilities?CountryId=${this.countryId}`).then((res) => {
        let { data: facilities } = res;
        facilities = [...facilities];
        this.facilities = facilities;
      });
    },
    getCompany() {
      this.axios.get(`${this.apiUrl}/web-analytics/common/ppv-company?CountryId=${this.countryId}`).then((res) => {
        let { data } = res;
        this.company = data.company;
        this.srcglkey = data.srcglkey;
        this.storeValue("Company", data.company);
        this.storeValue("srcglkey", data.srcglkey);
      });
    },
    onCountryChange(value) {
      this.countryId = value;
      this.getFacilities();
      this.getCompany();
    },
    storeValue(resourceKey, value) {
      this.createForm = {
        ...this.createForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateMaintainPostings = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "web-analytics.common.ppv-countries", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.ppv-segment5", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.gjs", "api-url": _vm.apiUrl, "redirect-route": "/web-analytics/maintain-postings" } }, [_c("create-maintain-postings")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateMaintainPostings
  },
  data() {
    return {
      apiUrl,
      CreateMaintainPostings
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
